import React from 'react';
import { useEffect } from 'react';
import Eta from '../../../components/ETA/Eta';
import '../ModuleGlobal.css';
import './Module.scss';
import ModuleButtonModal from '../../../components/ModuleButtonModal/ModuleButtonModal.js';
import DidYouKnow from '../../../assets/SVG/Didyouknow.svg';
import RSDPModuleLogo from '../../../components/RSDPModuleLogo/RSDPModuleLogo';
import { useDispatch, useSelector } from 'react-redux';
import { setLogged, setRedirect } from '../../../actions';


function Module() {

const dispatch = useDispatch();
const isLogged = useSelector(state => state.isLogged);

async function logout() {
    return await fetch('https://rsdp-backend.herokuapp.com/user/logout', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
        }, 
        credentials: 'same-origin'
    })
    .then( () => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('User_Id');
        window.localStorage.removeItem('logged_in');
        window.localStorage.removeItem('authToken');
    });
    // .then(data => console.log(data));
    // .then(data => data.json())
    // .then(data => setAuth(data.tokenValid));
}

useEffect(() => {    
    if(!window.localStorage.getItem('authToken') && isLogged){
        logout();
        dispatch(setLogged(false))
        dispatch(setRedirect(true, `/`));
    }
}, [])


    async function DownloadMedia() {
        return fetch('https://rsdp-backend.herokuapp.com/download1', {
            method: 'get',
            headers: {
                'Authorization': window.localStorage.getItem('authToken'),
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = 'Module 1 Preparation Action Items';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // alert('your file has downloaded!'); // or you know, something with better UX...
        })
        .catch(() => alert('Issue Downloading File. Please Try Again Later'));
    }
    
    const handleDownload = () => {
        DownloadMedia();
    };
    return (
        <div className="module1">
            <RSDPModuleLogo />
            <div className="module1__header module__header">
                <div className="module__header__text">
                    <h3>MODULE ONE</h3>
                    <h1>Preparation</h1>
                </div>
                <Eta time={10} />
            </div>
            <div className="module1__body">
                <div className="module1__body__video">
                <video id="Video" autoplay controls>
                    <source src={`https://rsdp-backend.herokuapp.com/video/1${window.localStorage.getItem('authToken')}`} type="video/mp4"></source>
                </video>
                </div>
                <div className="module1__body__main">
                    <p className="KeyLearningPoints">
                        Key Learning Points
                    </p>
                    {/* Text Section Start */}
                    <div className="textSection">
                        <div className="textSection__large">
                            <p>How to</p>
                            <h1>
                                Maximise your
                                chances of success
                                at recruitment events
                            </h1>
                        </div>
                        <div className="textSection__small">
                            <p>
                            You have probably invested a lot of time and effort finding a job that you really want, passed an initial screening and now you face the last hurdle, the interview. Do you realise how difficult it is to reach this point?
                            <br />
                            </p>
                        </div>
                    </div>
                    {/* Text Section End */}
                    {/* Quote Section Start */}
                    <div className="module1__quote">
                        <img src={DidYouKnow} alt="Did you know"/>
                        <div className="module1__quote__text">
                            <h2>“98% of job seekers are eliminated at the initial screening and only the ‘Top 2%’ of candidates make it to the interview”</h2>
                            <h5>Robert Meier</h5>
                            <h4>President of Job Market Experts.</h4>
                        </div>
                    </div>
                    {/* Quote Section Start */}
                    {/* Modal Button Section Start */}
                    <div className="module1__body__buttons__mod1">
                        <p>
                            Explore below to find out what you can do to prepare 
                            for your next recruitment event.
                            {/* <span>••••••••••••••••••••••</span><br /> */}
                        </p>
                        <ModuleButtonModal type="1x3"/>
                    </div>
                    {/* Modal Button Section Start */}
                    
                    {/* Activity Section Start */}
                    <div className="module1__body__download">
                        <h2>Now it's time to work on your action plan</h2>
                        <h3>Press the Download Button to make detailed personal preparations.</h3>
                        <button onClick={handleDownload}>Download</button>
                    </div>
                    {/* Activity Section End */}
                </div>
            </div>
        </div>
    )
    
}

export default Module
